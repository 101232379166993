import { Controller } from "@hotwired/stimulus"
import Plausible from "plausible-tracker"

export default class extends Controller {
	static values = {
		domain: String
	}

	connect() {
		this.plausible = Plausible({
			domain: "panini.house"
		})
	}

	trackEvent({ params }) {
		let props = params
		delete props.event

		this.plausible.trackEvent(params.event, {
			props: props
		})
	}
}
